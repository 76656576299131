@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

*{
    font-family: 'Roboto', sans-serif;
    /* outline: 1px solid black; */
}

body {
    background: url("https://imgur.com/mlfI2tR.png");
    background-size: cover;
    background-position: bottom;
    height: 100vh;
    width: 100vw;
}

.main {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding: 0px 80px;
    height: 100%;
    margin: 20px 10px;
}

header {
  display: inline-flex;
  width: 100% !important;
  margin-bottom: 70px;
  align-items: center;
  justify-content: space-between;
}

.logo {
    width: auto;
    height: auto;
}

.language {
    display: inline-flex;
    width: 60px;
    justify-content: space-evenly;
}

a {
    text-decoration: none;
}

h1 {
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 48px;
    margin-bottom: 50px !important;
    font-weight: bold !important;
    letter-spacing: 1px;
}

p {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.02em;
}

.stay-connected p {
    font-weight: bold;
    margin: 35px 0px 10px;
}

.subscribe {
    background-color: #F7F8FC;
    /* width: 530px; */
    height: 50px;
    border-radius: 5px!important;
}

.subscribe input {
    border-right: none;
    border-radius: 5px!important;
}

 .button {
    position: absolute;
    right: 1px;
    top: 1px;
    float: right;
    margin: 2px;
    background-color: #AF3471;
    height: 45px;
    width: 33%;
    border-radius: 5px !important;
    border: none;
    color: #FFFFFF;
    font-size: 16px;
    z-index: 10;
}

.subscribe button:hover {
    opacity: 90%;
}

.line {
    border: 1px solid #b2b0b0;
    width: 100%;
    margin: 90px 0px 20px;
}

.contact {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.contact a {
    margin: 10px;
    /* height: 30px;
    width: 30px; */
}

.email {
    border: 0.5px solid #b2b0b0;
    border-radius: 5px;
    width: auto;
    height: 27px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    text-decoration: none;
    font-size: 16px;
}

.email img {
    width: 20px;
    height: 20px;
    margin: 5px 0px 5px 5px;
}

#email-contact {
    color: #FFFFFF;
}

.copyright {
    color: #FFFFFF;
    font-size: 14px;
    margin: 30px auto;
    text-align: center;
    width: 235px;
}

.copyright img {
    width: 12px;
    height: 12px;
    margin: 0px 0px 3px 0px;
}

@media screen and (max-width: 576px) {
    body {
        background-image: url("https://imgur.com/IpPP4Uj.png");
    }

    .logo img{
        width: 150px;
        height: 60px;
    }
}